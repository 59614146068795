import ErrorPage from "./components/ErrorPage";
import App from './App';
import ReadyToBegin from "./components/ReadyToBegin";
import InternalContentCreator from "./components/InternalContentCreator";
import LinkMap from "./components/LinkMap";
import ComingSoon from "./components/ComingSoon";
import {ProgramType} from "./GlobalTypes";
import PlusContentCreator from "./components/PlusContentCreator";
import Redirect from "./components/Redirect";
import PlusSummary from "./components/PlusSummary";
import Submitted from "./components/Submitted";
import BasicContentCreator from "./components/BasicContentCreator";
import BasicSummary from "./components/BasicSummary";
import NewsAdmin from "./components/NewsAdmin";
import News from "./components/News";

const routes = [
  {
    path: "/",
    element: <Redirect url={"home.html"} />,
    errorElement: <App component={<ErrorPage />}/>,
  },
  {
    path: "/link-local",
    element: <Redirect url={"link-local.html"} />,
    errorElement: <App component={<ErrorPage />}/>,
  },
  {
    path: "/local-information",
    element: <Redirect url={"local-information.html"} />,
    errorElement: <App component={<ErrorPage />}/>,
  },
  {
    path: "/ready-basic",
    element: <App component={<ReadyToBegin programType={ProgramType.Basic} />}/>,
    errorElement: <App component={<ErrorPage />}/>,
  },
  {
    path: "/ready-plus",
    element: <App component={<ReadyToBegin programType={ProgramType.Plus} />}/>,
    errorElement: <App component={<ErrorPage />}/>,
  },
  {
    path: "/map",
    element: <App component={<LinkMap />}/>,
    errorElement: <App component={<ErrorPage />}/>,
  },
  {
    path: "/coming-soon",
    element: <App component={<ComingSoon />}/>,
    errorElement: <App component={<ErrorPage />}/>,
  },
  {
    path: "/plus-content-creator",
    element: <App component={<PlusContentCreator />}/>,
    errorElement: <App component={<ErrorPage />}/>,
  },
  {
    path: "/plus-summary",
    element: <App component={<PlusSummary />}/>,
    errorElement: <App component={<ErrorPage />}/>,
  },
  {
    path: "/submitted",
    element: <App component={<Submitted />}/>,
    errorElement: <App component={<ErrorPage />}/>,
  },
  {
    path: "/basic-content-creator",
    element: <App component={<BasicContentCreator />}/>,
    errorElement: <App component={<ErrorPage />}/>,
  },
  {
    path: "/basic-summary",
    element: <App component={<BasicSummary />}/>,
    errorElement: <App component={<ErrorPage />}/>,
  },
  {
    path: "/I1qRlWdvvBN2Zg5AkS74dhHHqQgI3iiULpVasUCmsLl4EAt7YZ",
    element: <App component={<InternalContentCreator />}/>,
    errorElement: <App component={<ErrorPage />}/>,
  },
  {
    path: "/I1qRlWdvvBN2Zg5AkS74dhHHqQgI3iiULpVasUCmsLl4EAt7YZ/news-admin",
    element: <App component={<NewsAdmin />}/>,
    errorElement: <App component={<ErrorPage />}/>,
  },
  {
    path: "/I1qRlWdvvBN2Zg5AkS74dhHHqQgI3iiULpVasUCmsLl4EAt7YZ/news",
    element: <App component={<News />}/>,
    errorElement: <App component={<ErrorPage />}/>,
  },
]

export default routes;
