import React, {useState, useEffect} from 'react';

const News = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    const divStyle = {
        marginTop: '100px',
        marginBottom: '30px',
        marginLeft: '25px',
        marginRight: '25px',
        minWidth: '900px'
    };

    useEffect(() => {
        const getPosts = async () => {
            const url: string = (process.env.REACT_APP_LAMBDA_URL as string) + '/news-posts';
            const response = await fetch(url, {method: "GET",});
            const data = await response.json();
            setPosts(data);
            setLoading(false);
        };
        getPosts();
    }, []); // Empty dependency array to run only once on mount

    if (loading) {
        return <div style={divStyle}><h4>Loading...</h4></div>;
    }

    return (
        <div style={divStyle}>
            {posts.map((post, index) => (
                <div style={{marginBottom: '10px'}} key={index}>
                    <h4>{post['title']}</h4>
                    {post['post_date']}
                    <hr style={{width: '105px', marginLeft: '0', marginTop: '25px', marginBottom: '25px'}}/>
                </div>
            ))}
        </div>
    );
}

export default News;
